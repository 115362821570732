import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

import RedditIcon from "../assets/icons/reddit.svg";
import DiscordIcon from "../assets/icons/discord.svg";

const SocialIconContainer = styled("a")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "50px",
  width: "50px",
  borderRadius: "50%",
  overflow: "hidden",
  backgroundColor: "var(--primary-color)",
  cursor: "pointer",
});

const SocialIconImage = styled("img")(({ size }: { size: string }) => ({
  height: size,
  width: size,
}));

interface SocialIconsProps {
  isMobile?: boolean;
}

const SocialIcons: React.FC<SocialIconsProps> = ({ isMobile }) => {
  const gap = isMobile ? "20px" : "34px"; // Adjust gap based on screen size

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap }}>
      <SocialIconContainer href="https://www.reddit.com/r/Whander/" target="_blank">
        <SocialIconImage src={RedditIcon} alt="Reddit" size={isMobile ? "30px" : "38px"} />
      </SocialIconContainer>
      <SocialIconContainer href="https://discord.gg/FX6pqkdEHj" target="_blank">
        <SocialIconImage src={DiscordIcon} alt="Discord" size={isMobile ? "27px" : "35px"} />
      </SocialIconContainer>
    </Box>
  );
};

export default SocialIcons;
