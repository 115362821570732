import React, { useState } from "react";
import { useMediaQuery } from "@mui/material";  // Importing useMediaQuery
import characterPreview from "../assets/character-preview.png";
import "../styles/Features.css";

const Features: React.FC = () => {
    const [selectedFeatureIndex, setSelectedFeatureIndex] = useState(0); // State for the current image
  const [selectedFeature, setSelectedFeature] = useState(characterPreview); // State for the current image
  const isMobile = useMediaQuery("(max-width:1100px)"); // Detect if the screen width is less than 1100px

  // Define an array of features for mapping
  const features = [
    {
      id: 1,
      icon: "public",
      title: "Epic Quests Across Diverse Worlds",
      description: [
        "Find hidden treasures",
        "Meet enigmatic locals",
        "Discover secrets waiting to be uncovered",
      ],
      image: characterPreview, // Placeholder for now
    },
    {
      id: 2,
      icon: "face_2",
      title: "Forge Your Unique Whanderer",
      description: [
        "Gather rare items",
        "Customize your character",
        "As you level up, unlock powerful abilities",
      ],
      image: characterPreview,
    },
    {
      id: 3,
      icon: "handshake",
      title: "Unite and Compete with others",
      description: [
        "Rise to the top of leaderboards",
        "Band together with friends to tackle epic quests",
        "Share individual activities or progress with friends",
      ],
      image: characterPreview, // Placeholder
    },
    {
      id: 4,
      icon: "forest",
      title: "Nurture Your Ever-Growing Tree",
      description: [
        "Every adventure nourishes your personal tree",
        "Watch it take on unique features with each quest",
        "Share your tree with friends",
      ],
      image: characterPreview, // Placeholder
    },
  ];
    
  const handleNext = () => {
    setSelectedFeatureIndex((prevIndex) => (prevIndex + 1) % features.length);
  };

  const handlePrev = () => {
    setSelectedFeatureIndex(
      (prevIndex) => (prevIndex - 1 + features.length) % features.length
    );
  };

  if (isMobile) {
    // Mobile layout with overlay and navigation arrows
    return (
        <div className="features-container">
          <div className="feature-image-container">
            <img
              src={features[selectedFeatureIndex].image}
              alt="Feature Preview"
              className="feature-image"
            />
            <button className="arrow-button left-arrow" onClick={handlePrev}>
              <span className="material-icons-outlined">arrow_back</span>
            </button>
            <button className="arrow-button right-arrow" onClick={handleNext}>
              <span className="material-icons-outlined">arrow_forward</span>
            </button>
            <div className="overlay-feature-box">
              <div className="feature-title">
                <span className="material-icons-outlined">
                  {features[selectedFeatureIndex].icon}
                </span>
                {features[selectedFeatureIndex].title}
              </div>
                    <div className="feature-body">
                        <br></br>
                <ul>
                  {features[selectedFeatureIndex].description.map((desc, index) => (
                    <li key={index}>{desc}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      );
  }

  return (
    <div className="features-container">
      <div className="features-list">
        {features.map((feature) => (
          <div
            key={feature.id}
            className="feature-box"
            onMouseEnter={() => setSelectedFeature(feature.image)} // Set the image on hover
          >
            <div className="feature-title">
              <span className="material-icons-outlined">{feature.icon}</span>
              {feature.title}
            </div>
            <div className="feature-body">
              <ul>
                {feature.description.map((desc, index) => (
                  <li key={index}>{desc}</li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
      <div className="feature-image-container">
        <img
          src={selectedFeature}
          alt="Feature Preview"
          className="feature-image"
        />
      </div>
    </div>
  );
};

export default Features;
