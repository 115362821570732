import React from "react";
import Header from "../components/Header";
import CallToAction from "../components/CallToAction";
import Nutshell from "src/components/Nutshell";
import Features from "src/components/Features";
import Signup from "src/components/Signup";

const Home: React.FC = () => {
  return (
    <div>
      <div className="container-center">
        <Header />
        <CallToAction />

        {/* What is Whander Section */}
        <div id="what-is-whander" className="displayLarge">
          What is Whander?
        </div>
        <Nutshell />

        {/* Features Section */}
        <div id="features" className="displayLarge">
          Features
        </div>
        <Features />

        {/* Join the Adventure Section */}
        <div id="join-the-adventure" className="displayLarge">
          Join the adventure
        </div>
        <Signup />
      </div>
    </div>
  );
};

export default Home;
