import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../config/firebaseConfig";
import signpostImage from "../assets/signpost.png";
import patreonIcon from "../assets/icons/patreon.png";
import SocialIcons from "./SocialIcons";
import "../styles/Signup.css";

const Signup: React.FC = () => {
  const isMobile = useMediaQuery("(max-width:768px)");

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (message) {
      const timeout = setTimeout(() => {
        setMessage(""); // Clear the message after 4 seconds
      }, 4000);

      return () => clearTimeout(timeout);
    }
  }, [message]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log("Form Submitted", email);

    try {
      // Add a new document with a generated ID
      const docRef = await addDoc(collection(db, "interest-emails"), {
        email: email,
        signup_date: new Date(), // Capture the current timestamp
      });

      console.log("Document written");

      setMessage("Thank you for signing up!");
      setEmail(""); // Clear input after successful signup
    } catch (error) {
      console.error("Error adding document: ", error);
      setMessage("Something went wrong, please try again.");
    }
  };

  return (
    <div className="signup-container">
      <div className="stay-loop-section">
        <div className="subtitleText">Stay in the loop</div>
        {isMobile && <SocialIcons isMobile={true} />}

        <img src={signpostImage} alt="Signpost" className="signpost-image" />
        <p className="supportingText">
          Enter your email to receive exclusive updates, tips, and sneak peeks
          into the world of Whander.
        </p>

        <form
          onSubmit={handleSubmit}
          className={`email-signup ${isMobile ? "email-signup-mobile" : ""}`}
        >
          <input
            type="email"
            placeholder="Enter your email here..."
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          {!isMobile && (
            <button type="submit" className="button-primary">
              Sign up
            </button>
          )}
        </form>

        {isMobile && (
          <button
            type="submit"
            onClick={handleSubmit}
            className="button-primary mobile-button"
          >
            Sign up
          </button>
        )}

        {message && <p className="message">{message}</p>}
      </div>

      <hr className="divider" />

      <div className="private-beta-section">
        <h2 className="subtitleText">Want to join the private beta?</h2>
        <a
          href="https://www.patreon.com/Whander"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="button-patreon">
            <img
              src={patreonIcon}
              alt="Become a Patron"
              className="patreon-icon"
            />
            Become a Patron
          </button>
        </a>

        <p className="supportingText">
          Even more content available to Patreon supporters, including:
        </p>
        <ul className="feature-body signup-body">
          <li>Early access to the private beta</li>
          <li>Exclusive Whander-themed digital wallpapers</li>
          <li>Game art creation videos</li>
          <li>Character creator preview</li>
        </ul>
      </div>
    </div>
  );
};

export default Signup;
