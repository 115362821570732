import React, { useState } from "react";
import mapImage from "../assets/map.png";
import bootImage from "../assets/boot.png";
import chestImage from "../assets/chest.png";

const Nutshell: React.FC = () => {
  // Toggle states for mobile view
  const [isFirstOpen, setIsFirstOpen] = useState(false);
  const [isSecondOpen, setIsSecondOpen] = useState(false);
  const [isThirdOpen, setIsThirdOpen] = useState(false);

  return (
    <div className="nutshell-section">
      {/* Desktop Layout */}
      <div className="nutshell-container desktop-view">
        <div className="subtitleText">
          Whander is a fantasy adventure exercise app where movement in your
          daily life allows you to complete quests in a vast world.
        </div>
        <br></br>
        <div className="insightContainer">
          <div className="insightText">
            <div className="subtitleText">Start off in the town of Whander</div>
            <div className="supportingText">
              Create your avatar and embark on your adventure.
            </div>
          </div>

          <img src={mapImage} alt="Map" className="insightImage" />
        </div>
        <div className="insightContainer">
          <div className="insightText">
            <div className="subtitleText">Step into the Limitless Forest</div>
            <div className="supportingText">
              A vast wood that can transport you to other worlds.
            </div>
          </div>
          <img src={bootImage} alt="Boot" className="insightImage" />
        </div>
        <div className="insightContainer">
          <div className="insightText">
            <div className="subtitleText">Grow your personal tree</div>
            <div className="supportingText">
              Complete quests to grow your tree and climb the leaderboard.
            </div>
          </div>
          <img src={chestImage} alt="Chest" className="insightImage" />
        </div>
      </div>

      {/* Mobile Collapsible View */}
      <div className="nutshell-container mobile-view">
        <div className="toggle-section">
          <div
            className="toggle-header"
            onClick={() => setIsFirstOpen(!isFirstOpen)}
          >
            <div className="subtitleText">{isFirstOpen ? "▼" : "▶"} Start off in the town of Whander</div>
          </div>
          {isFirstOpen && (
            <div className="insightContainer">
              <div className="insightText">
                <div className="supportingText">
                  Create your avatar and embark on your adventure.
                </div>
              </div>

              <img src={mapImage} alt="Map" className="insightImage" />
            </div>
          )}
        </div>

        <div className="toggle-section">
          <div
            className="toggle-header"
            onClick={() => setIsSecondOpen(!isSecondOpen)}
          >
            <div className="subtitleText">{isSecondOpen ? "▼" : "▶"} Step into the Limitless Forest</div>
          </div>
          {isSecondOpen && (
            <div className="insightContainer">
              <div className="insightText">
                <div className="supportingText">
                  A vast wood that can transport you to other worlds.
                </div>
              </div>
              <img src={bootImage} alt="Boot" className="insightImage" />
            </div>
          )}
        </div>

        <div className="toggle-section">
          <div
            className="toggle-header"
            onClick={() => setIsThirdOpen(!isThirdOpen)}
          >
            <div className="subtitleText">{isThirdOpen ? "▼" : "▶"} Grow your personal tree</div>
          </div>
          {isThirdOpen && (
            <div className="insightContainer">
              <div className="insightText">
                <div className="supportingText">
                  Complete quests to grow your tree and climb the leaderboard.
                </div>
              </div>
              <img src={chestImage} alt="Chest" className="insightImage" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Nutshell;
