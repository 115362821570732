import React from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
} from "@mui/material";
import FortIcon from "@mui/icons-material/Fort";
import ExploreIcon from "@mui/icons-material/Explore";
import HikingIcon from "@mui/icons-material/Hiking";
import SocialIcons from "./SocialIcons";
import WhanderIcon from "../assets/icons/whander-icon-3.png";
import "../styles/Menu.css"; // Importing CSS

interface MenuProps {
  menuOpen: boolean;
  toggleMenu: (open: boolean) => void;
}

const Menu: React.FC<MenuProps> = ({ menuOpen, toggleMenu }) => {
  const menuItems = [
    { text: "What is Whander?", icon: <FortIcon />, id: "what-is-whander" },
    { text: "Features", icon: <ExploreIcon />, id: "features" },
    {
      text: "Join the Adventure",
      icon: <HikingIcon />,
      id: "join-the-adventure",
    },
  ];

  const handleMenuItemClick = (id: string) => {
    // Find the section by ID and scroll to it
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
    // Close the menu after clicking
    toggleMenu(false);
  };

  return (
    <Drawer anchor="left" open={menuOpen} onClose={() => toggleMenu(false)}>
      <Box className="menu-container">
        {/* Whander Icon and Title */}
        <div className="menu-header">
          <img src={WhanderIcon} alt="Whander Logo" className="whander-icon" />
          <div className="whander-title">Whander</div>
        </div>

        {/* Menu Items */}
        <List className="menu-list">
          {menuItems.map((item, index) => (
            <ListItem
              key={index}
              className="menu-list-item"
              sx={{
                color: "var(--primary-20)", // Applying color here
                fontFamily: "Merriweather, serif",
                fontSize: "16px",
                padding: "15px",
                borderRadius: "12px",
                "&:hover": {
                  backgroundColor: "rgba(193, 204, 200, 0.5)", // Hover effect
                },
              }}
              onClick={() => handleMenuItemClick(item.id)} // Call custom scroll function
            >
              <ListItemIcon
                className="menu-list-icon"
                sx={{ color: "var(--primary-20)", fontSize: "28px" }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                sx={{
                  color: "var(--primary-20)", // Applying color here
                  fontFamily: "Merriweather, serif",
                  fontSize: "20px",
                }}
              />
            </ListItem>
          ))}
        </List>

        {/* Social Icons */}
        <Box className="menu-social-icons">
          <SocialIcons isMobile={true} />
        </Box>
      </Box>
    </Drawer>
  );
};

export default Menu;
