import React, { useState } from "react";
import { AppBar, Toolbar, IconButton, Box, useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "./Menu"; // Import the new Menu component
import SocialIcons from "./SocialIcons"; // Import the SocialIcons component
import WhanderIcon from "../assets/icons/whander-icon-3.png";

const Header: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false); // State for controlling menu open/close
  const isMobile = useMediaQuery("(max-width:1100px)");

  const toggleMenu = (open: boolean) => {
    setMenuOpen(open);
  };

  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: "transparent", boxShadow: "none" }}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          
          {/* Left-aligned for Desktop: Logo and Hamburger Menu */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <a href="/" className="logo">
              <img src={WhanderIcon} alt="Whander app logo" style={{ height: "50px", width: "50px" }} />
            </a>

            {/* Render Hamburger Icon on Left for non-mobile screens */}
            {!isMobile && (
              <IconButton
                edge="start"
                sx={{
                  color: "var(--primary-color)",
                  height: "50px",
                  width: "50px",
                  padding: 0,
                  marginLeft: "34px", // Add spacing for desktop
                }}
                aria-label="menu"
                onClick={() => toggleMenu(true)} // Open the menu on click
              >
                <MenuIcon sx={{ fontSize: "40px" }} />
              </IconButton>
            )}
          </Box>

          {/* Centered: Title for both Desktop and Mobile */}
          <div className="mainTitle">Whander</div>

          {/* Conditionally Render Social Icons for Desktop or Hamburger for Mobile */}
          {!isMobile ? (
            <SocialIcons isMobile={false} />
          ) : (
            // Render Hamburger Icon on the Right for Mobile
            <IconButton
              edge="end"
              sx={{
                color: "var(--primary-color)",
                height: "40px",
                width: "40px",
                padding: 0,
              }}
              aria-label="menu"
              onClick={() => toggleMenu(true)} // Open the menu on click
            >
              <MenuIcon sx={{ fontSize: "40px" }} />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      {/* Drawer Menu */}
      <Menu menuOpen={menuOpen} toggleMenu={toggleMenu} />
    </>
  );
};

export default Header;
